export const MAIN_NAV = [
  {
    "title": "Asuransi Mobil",
    "link": "/asuransi/mobil/",
    "childItems": [
      {
        "title": "Asuransi Mobil Terbaik",
        "link": "/asuransi/mobil/",
      },
      {
        "title": "Asuransi Mobil All Risk",
        "link": "/asuransi/all-risk/",
      },
      {
        "title": "Asuransi Mobil TLO",
        "link": "/asuransi/tlo/",
      },
      {
        "title": "Asuransi Mobil Syariah",
        "link": "/asuransi/mobil-syariah/",
      },
    ],
  },
  {
    "title": "Asuransi Kesehatan",
    "link": "/asuransi/kesehatan/",
    "childItems": [
      {
        "title": "Asuransi Kesehatan Terbaik",
        "link": "/asuransi/kesehatan/",
      },
      {
        "title": "Asuransi Kesehatan Keluarga",
        "link": "/asuransi/kesehatan-keluarga/",
      },
      {
        "title": "Asuransi Kesehatan Orangtua",
        "link": "/asuransi/kesehatan-orangtua/",
      },
      {
        "title": "Asuransi Penyakit Kritis",
        "link": "/asuransi/penyakit-kritis/",
      },
      {
        "title": "Hospital Cash Plan",
        "link": "/asuransi/hospital-cash-plan/",
      },
      {
        "title": "Asuransi Melahirkan",
        "link": "/asuransi/melahirkan/",
      },
      {
        "title": "Asuransi Rawat Jalan",
        "link": "/asuransi/rawat-jalan/",
      },
      {
        "title": "Asuransi Gigi",
        "link": "/asuransi/gigi/",
      },
      {
        "title": "Asuransi Kesehatan Syariah",
        "link": "/asuransi/kesehatan-syariah/",
      }
    ],
  },
  {
    "title": "Lainnya",
    "link": "/#/",
    "childItems": [
      {
        "title": "Asuransi Motor",
        "link": "/asuransi/motor/",
      },
      {
        "title": "Asuransi Karyawan",
        "link": "/asuransi/karyawan/",
      }
    ],
  },
  {
    "title": "Rekan",
    "link": "/#/",
    "childItems": [
      {
        "title": "Astra Garda Oto",
        "link": "/asuransi/mobil-garda-oto/",
      },
      {
        "title": "Zurich Syariah",
        "link": "/asuransi/zurich-syariah/",
      },
      {
        "title": "ACA",
        "link": "/asuransi/mobil-aca/",
      },
      {
        "title": "Roojai-Sompo",
        "link": "/asuransi/mobil-sompo/",
      },
      {
        "title": "Mega Insurance",
        "link": "/asuransi/mobil-mega-insurance/",
      },
      {
        "title": "Simas Insurtech",
        "link": "/asuransi/simas-insurtech/",
      },
      {
        "title": "Sinarmas",
        "link": "/asuransi/kesehatan-sinar-mas/",
      },
      {
        "title": "AXA Insurance",
        "link": "/asuransi/kesehatan-axa/",
      },
      {
        "title": "Sun Life",
        "link": "/asuransi/sunlife/",
      },
      {
        "title": "Lippo General Insurance",
        "link": "/asuransi/kesehatan-lippo/",
      },
      {
        "title": "April International",
        "link": "/asuransi/april-international/",
      },
      {
        "title": "MAG",
        "link": "/asuransi/mag/",
      },
      {
        "title": "CAR Life",
        "link": "/asuransi/kesehatan-car/",
      },
    ],
  },
  {
    "title": "Artikel & Tips",
    "link": "https://lifepal.co.id/media/",
    "childItems": [
      {
        "title": "Mengerti Asuransi",
        "link": "/media/apa-itu-asuransi/",
        "childItems": [
          {
            "title": "Polis Asuransi",
            "link": "/media/asuransi/polis-asuransi/",
          },
          {
            "title": "Premi Asuransi",
            "link": "/media/asuransi/apa-itu-premi-asuransi/",
          },
          {
            "title": "Jenis Asuransi",
            "link": "/media/asuransi/jenis-asuransi/",
          },
          {
            "title": "Risiko Asuransi",
            "link": "/media/asuransi/risiko-asuransi/",
          },
          {
            "title": "Klaim Asuransi",
            "link": "/media/asuransi/klaim-asuransi/",
          }
        ],
      },
      {
        "title": "Artikel Lainnya",
        "link": "/media/",
        "childItems": [
          {
            "title": "Semua Aritkel",
            "link": "/media",
          },
          {
            "title": "Asuransi",
            "link": "/media/asuransi/",
          },
          {
            "title": "Asuransi Mobil",
            "link": "/media/asuransi-mobil/",
          },
          {
            "title": "Asuransi Kesehatan",
            "link": "/media/asuransi-kesehatan/",
          },
          {
            "title": "Perusahaan Asuransi",
            "link": "/media/perusahaan-asuransi/",
          },
          {
            "title": "Tips Kendaraan dan Asuransi",
            "link": "/media/tips-kendaraan-asuransi/",
          },
          {
            "title": "Tips Kesehatan dan Asuransi",
            "link": "/media/tips-kesehatan-asuransi/",
          },
          {
            "title": "Kalkulator & Kuis",
            "link": "/media/kalkulator/",
          },
        ],
      }
    ],
  },
  {
    "title": "Layanan",
    "link": "#",
    "position": "right",
    "childItems": [
      {
        "title": "Tentang Lifepal",
        "link": "/tentang/",
      },
      {
        "title": "Bantuan",
        "link": "/pusat-bantuan/",
      },
      {
        "title": "Ketentuan",
        "link": "/ketentuan/",
      },
      {
        "title": "Cara Pembelian",
        "link": "/cara-beli/",
      }
    ],
  }
];